import { alpha } from "@theme-ui/color"

const headings = {
  h1: {
    fontSize: [7, null, null, 8],
    lineHeight: "h1",
    fontFamily: "heading",
    fontWeight: "700",
  },
  h2: {
    fontSize: [5, null, null, 6],
    lineHeight: "h2",
    fontFamily: "heading",
    fontWeight: "700",
  },
  h3: {
    fontSize: [3, null, null, 4],
    lineHeight: "h3",
    fontFamily: "heading",
    fontWeight: "700",
  },
  h4: {
    fontSize: 3,
    fontFamily: "heading",
    fontWeight: "700",
  },
  h5: {
    fontSize: 2,
    fontFamily: "heading",
    fontWeight: "700",
  },
  h6: {
    fontSize: 1,
    fontFamily: "heading",
    fontWeight: "700",
  },
  a: {
    color: "primary",
    opacity: "1",
    transition: "all 0.3s ease",
    "&:hover": {
      opacity: "0.8",
    },
  },
}

const theme = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1400px", "1600px"],
  colors: {
    text: "#222222",
    background: "#ffffff",
    primary: "#1EBF36",
    muted: "#F5F5F5",
    highlight: "#FBFBFB",
    invalid: "#FF3333",
    white: "#ffffff",
    gray: "#ccc",
  },
  fonts: {
    heading: "'Roboto Condensed', sans-serif",
    body: "'Source Sans Pro', sans-serif",
    nav: "'Roboto', sans-serif",
  },
  fontSizes: [14, 16, 18, 20, 24, 30, 36, 48, 70],
  lineHeights: {
    body: "1.5556",
    h1: "1.14",
    h2: "1.278",
    h3: "1.417",
  },
  images: {
    avatar: {
      width: 96,
      height: 96,
      borderRadius: 99999,
    },
  },
  text: {
    ...headings,
    subHeading: {
      fontFamily: "highlight",
      fontSize: "1.667rem",
    },
    btn: {
      fontSize: 2,
      fontWeight: "600",
      textDecoration: "none",
      textTransform: "uppercase",
      transition: "all 0.3s ease",
      lineHeight: "1",
    },
  },
  buttons: {
    primary: {
      variant: "text.btn",
      p: "14px 28px",
      borderRadius: "3px",
      display: ["block", null, "inline-block"],
      opacity: "1",
      textAlign: "center",
      cursor: "pointer",
      backgroundColor: "primary",
      color: "background",
      border: "1px solid",
      borderColor: "primary",
      "&:hover": {
        backgroundColor: alpha("primary", 0.65),
      },
      "&:disabled": {
        borderColor: "gray",
        bg: "gray",
        color: "#888",
        pointerEvents: "none",
      },
    },
  },
  forms: {
    label: {
      fontSize: 1,
      mb: 1,
    },
    input: {
      bg: "muted",
      px: 3,
      borderColor: "text",
      outline: "none",
      "&:hover, &:focus, &:active": {
        borderColor: "primary",
        bg: "white",
      },
      "&:disabled": {
        borderColor: "gray",
        bg: "gray",
        color: "#888",
      },
    },
    select: {
      bg: "muted",
      px: 3,
      borderColor: "text",
      outline: "none",
      "&:hover, &:focus, &:active": {
        borderColor: "primary",
        bg: "white",
      },
    },
  },
  layout: {
    container: {
      maxWidth: 1200,
    },
  },
  styles: {
    root: {
      fontSize: [2, null, 3],
      lineHeight: "body",
      fontFamily: "body",
      fontWeight: "400",
    },
    ...headings,
    img: {
      maxWidth: "100%",
      height: "auto",
    },
  },
}

export default theme
